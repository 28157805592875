<template>
  <div class="background">
    <v-container>
      <v-row class="pt-2">
        <v-col cols="12" md="6">
          <ContactForm />
        </v-col>
        <v-col cols="12" md="6">
          <ContactDetails />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import ContactDetails from '@/components/ContactDetails'

export default {
  components: {
    ContactForm,
    ContactDetails
  },
  head: {
    title: function () {
      return {
        inner: 'Contact'
      }
    }
  }
}
</script>

<style>
  .background {
    background-color: white !important;
  }
</style>
