<template>
  <div>
    <h2>CONTACT US</h2>
    <h5 class="h5 mt-4"><v-icon>mdi-map-marker</v-icon><b>Address: </b> 835 Churchill Avenue, <br> Sudbury, Ontario <br> P3A 4A2, Canada</h5>
    <h5 class="h5"><v-icon>mdi-phone</v-icon><b>Phone: </b> <a class="text-decoration-none" style="color:green" href="tel:705-566-6150"> (705) 566-6150</a></h5>
    <h5 class="h5 mb-4"><v-icon>mdi-email</v-icon><b>Phone: </b> <a class="text-decoration-none" style="color:green" href="mailto:info@sudburyadventist.org">info@sudburyadventist.org</a></h5>
    <v-divider />
    <h3 class="h4 mt-2">Pastor Elvis Dumitru</h3>
    <h5 class="h5"><v-icon>mdi-phone</v-icon><b>Phone: </b> <a class="text-decoration-none" style="color:green" href="tel:437-299-2630"> 437-299-2630</a></h5>
    <h5 class="h5 mb-4"><v-icon>mdi-email</v-icon><b>Phone: </b> <a class="text-decoration-none" style="color:green" href="mailto:edumitru@adventistontario.org">edumitru@adventistontario.org</a></h5>
    <v-divider />
    <h3 class="h4 mt-2">Services</h3>
    <h5 class="h5"><b>Sabbath School:</b> Saturdays at 9:45am</h5>
    <h5 class="h5 mb-2"><b>Worship:</b> Saturdays at 11:00am</h5>
    <v-divider />
    <h4 class="h4 my-2">Map</h4>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2745.6512956480997!2d-80.93691728420332!3d46.515012479127606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2f0091ef4d3549%3A0xee9dd50674aa9224!2sSudbury+Seventh-day+Adventist+Church!5e0!3m2!1sen!2sca!4v1558466689758!5m2!1sen!2sca"
      frameborder="0"
      width="100%"
      allowfullscreen=""
      style="border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'ContactDetails'
}
</script>
