<template>
  <div>
                
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <h2>Talk With Us</h2>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="fullname"
              :rules="nameRules"
              label="Full Name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email Address"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="subject"
              :rules="subjectRules"
              label="Subject"
              :items="items"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="message"
              :rules="messageRules"
              label="Contact Message"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          :disabled="!valid"
          color="primary"
          class="mr-4"
          @click="sendEmail"
        >
          <v-icon
            dark
            left
            color="white"
          >
            mdi-checkbox-marked-circle
          </v-icon>
          <div style="color:white">Send Message</div>
        </v-btn>
      </v-container>
    </v-form>
  </div>
</template>

<script>

import axios from 'axios'
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast)

const instance = axios.create({
  baseURL: process.env.VUE_APP_EMAIL_API_URL,
  timeout: 50000,
  headers: {
    Accept: '*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
  }
})

export default {
  name: 'ContactForm',
  data: () => ({
    items: ['Prayer Request', 'Bible Study Request', 'Prayer Testimony', 'Visitation', 'Other'],
    valid: false,
    fullname: '',
    nameRules: [
      (v) => !!v || 'Name is required'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    subject: '',
    subjectRules: [
      (v) => !!v || 'Subject is required'
    ],
    message: '',
    messageRules: [
      (v) => !!v || 'Message is required'
    ],
    loading: false
  }),
  methods: {
    async sendEmail() {

      this.loading = true

      const formData = new FormData()

      formData.append('senderName', this.fullname)
      formData.append('senderEmail', this.email)
      formData.append('recipients', 'info@sudburyadventist.org')
      formData.append('subject', this.subject)

      let emailBody = `Name: ${this.fullname}\r\n`

      emailBody += `Email: ${this.email}\r\n`
      emailBody += `Subject: ${this.subject}\r\n`
      emailBody += `Message: ${this.message}\r\n`

      formData.append('emailBody', emailBody)

      axios ({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_EMAIL_API_URL,
        headers: {
          Accept: '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
        }
      })
        .then((response) => {
          this.loading = false
          this.resetForm()
          this.successToast()
        })
        .catch((error) => {
          console.log(error)
          this.failedToast()
        })
        .finally(() => this.loading = false)
    },
    resetForm() {
      this.fullname = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    },
    successToast() {
      Vue.$toast.open({
        message: 'Message successfully sent',
        type: 'success',
        position: 'top-right',
        duration: 7000
      })
    },
    failedToast() {
      Vue.$toast.open({
        message: 'Sorry, we were unable to send your message!',
        type: 'error',
        position: 'top-right',
        duration: 7000
      })
    }
  }
}
</script>
